import compact from 'lodash/compact';
import React, { FC, SyntheticEvent } from 'react';

import Header from '../../../components/Header';
import IconArrowLeft from '../../../icons/ArrowLeft';

interface HeaderActionsProps {
  closeOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  displayClose?: boolean;
}

const HeaderActions: FC<HeaderActionsProps> = ({
  closeOnClick,
  displayClose,
}) => (
  <Header
    menu={{
      left: compact([
        displayClose && closeOnClick
          ? {
              color: 'neutral',
              iconLeft: IconArrowLeft,
              onClick: closeOnClick,
              size: 'md',
              square: true,
              variant: 'outline',
            }
          : undefined,
      ]),
    }}
  />
);

export default HeaderActions;
