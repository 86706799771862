import React, { FC } from 'react';

import { ViewProps } from './index';

const Split: FC<ViewProps> = ({ children }) => (
  <div className="cms-view-split">
    {children}
    {/* <div className="column"> */}
    {/* {props.children[0]} */}
    {/* </div> */}
    {/* <div className="column"> */}
    {/* {props.children[1]} */}
    {/* </div> */}
  </div>
);

export default Split;
