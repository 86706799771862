import { Field } from '@innedit/formidable';
import React, { FC } from 'react';

import { MenuType, NavBar } from '../Menu';
import ViewTitle from './Title';

const MyCustomInput: FC<{ input: any }> = ({ input: { value } }) => (
  <span>{value}</span>
);

interface TitleProps {
  className?: string;
  title: string | string[] | any;
}

const Title: FC<TitleProps> = ({ className, title }) => {
  if (Array.isArray(title)) {
    return (
      <ViewTitle className={className}>
        {title.map(item => (
          <Field
            key={`title_${item.attribut}`}
            component={MyCustomInput}
            disabled
            name={item.attribut}
          />
        ))}
      </ViewTitle>
    );
  }

  if ('object' === typeof title) {
    return (
      <ViewTitle className={className}>
        <Field component={MyCustomInput} disabled name={title.attribut} />
      </ViewTitle>
    );
  }

  return title ? <ViewTitle className={className}>{title}</ViewTitle> : null;
};

const className =
  'sticky top-14 py-3 px-6 md:px-1 md:-mx-1 flex justify-between space-x-6 z-30 bg-background bg-opacity-95';
const Header: FC<{
  description?: string;
  menu?: MenuType;
  title?: string | TitleProps;
}> = ({ description, menu, title }) => (
  <div className={className}>
    {menu && menu.left && menu.left.length > 0 && (
      <nav>
        <NavBar menu={menu.left} />
      </nav>
    )}
    {(description || title) && (
      <div className="flex-auto">
        {title && <Title title={title} />}
        {description && <p className="mt-6 text-md">{description}</p>}
      </div>
    )}
    {menu && menu.right && menu.right.length > 0 && (
      <nav>
        <NavBar menu={menu.right} />
      </nav>
    )}
  </div>
);

export default Header;
