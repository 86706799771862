import styled from '@emotion/styled';

export default styled.h1`
  color: #34313f;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.3334;

  span:after {
    content: ' ';
  }
`;
