import React, { FC, SyntheticEvent } from 'react';

import HeaderActions from './HeaderActions';

const EspaceItem: FC = ({ children }) => {
  const handleCloseOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    window.history.back();
  };

  return (
    <div>
      <HeaderActions closeOnClick={handleCloseOnClick} displayClose />
      <div>{children}</div>
    </div>
  );
};

export default EspaceItem;
